<template>
  <div class="container">
    <img src="@/assets/images/tg_coin.png" alt="" class="img-size">
    <div class="font" style="margin-top: 30px;">$BORED</div>
    <IOdometer class="font" style="margin-top: 20px;" :value="walletBalance" ></IOdometer>
  </div>
</template>

<script setup>
import "@/assets/fonts/font.css";
import IOdometer from 'vue3-odometer';
import 'odometer/themes/odometer-theme-default.css';

import {defineProps,defineExpose } from "vue";

const props = defineProps({
  walletBalance: {
    default: 0
  }
})

defineExpose({
  props
});
</script>

<style scoped>
.container {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.img-size{
    width: 200px;
    height: 200px;
}

.font{
    font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
    font-size: 30px;
    color: white;
}
</style>