<template>
  
  <div
    class="full-size"
    @click="handleClick"
    :style="{ backgroundColor: randomColor }"
  >
    <div class="container-mid">
      <div class="count-font" v-show="countisVisible">{{ count }}</div>
      <TGCoin
        class="full-size"
        v-show="cointisVisible"
        @restHome="restHomeData($event)"
        @getCoinAnimation="getCoinAnimation($event)"
        ref="coinRef"
        
      ></TGCoin>

    </div>
    <div class="wallet_container" style="position: absolute; top: 30px" @click.stop="balanceClick">
      <IOdometer class="wallet_num" :value="walletnum"></IOdometer>
      <div class="wallet_num" style="margin-left: 10px">{{ boredYet }}</div>
      <span class="underline"></span>
    </div>

    <div class="gift-container">
      <div class="energy-container">
        <img src="@/assets/images/bored_speed.png" alt="">
        <div class="join-font">{{ speedupTimesRemaining + '/' + speedupTimesMax }}</div>
      </div>
      
      <span class="spacer"></span>
      <div @click="joinClick($event)">
        <div class="energy-container">
        <img src="@/assets/images/bored_gift.png" alt="">
        <div class="join-font" >GIFT</div>
      </div>
        
    </div>

    </div>

    <TGJoin
      class="full-size"
      ref="joinRef"
      :isVisible="joinisVisible"
      :walletBalance="walletnum"
      @joinReword="joinRewordAdd($event)"
    />
    <TGWelcome
      class="full-size"
      @visible="welcomeVisble($event)"
      ref="welcomeRef"
    ></TGWelcome>

    <TGBalance class="full-size" v-show="balanceisVisible" :walletBalance="walletnum"></TGBalance>

  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import "@/assets/fonts/font.css";
import TGJoin from "./TGJoin.vue";
import TGWelcome from "./TGWelcome.vue";
import TGCoin from "./TGCoin.vue";
import TGBalance from "./TGBalance.vue";
import IOdometer from "vue3-odometer";
import { decode } from "js-base64";
import "odometer/themes/odometer-theme-default.css";
import { getUserInfo, tgRewardRecord ,putUserInfo} from "@/utils/api";
import { encryptedString } from "@/utils/tools";
import { Howl } from "howler";

const walletnum = ref(0);
const count = ref(30);
const randomColor = ref("black");
const joinisVisible = ref(false);
const countisVisible = ref(true);
const cointisVisible = ref(false);
const balanceisVisible = ref(false);
const userTgId = ref(0);
const countDownEnble = ref(true); //是否继续倒计时 倒计时结束弹出领币页时 禁止倒计时
const another = "ANOTHER\nMOST\nBORING\n30SEC\nEVER";
const boredYet = "$BORED";

// 定义与 ref 同名变量
const welcomeRef = ref(null);
const coinRef = ref(null);
const joinRef = ref(null);
const sound = ref(null);

const communityRef = ref(false);
const followAnnRef = ref(false);
const followXRef = ref(false);

const speedupTimesRemaining = ref(0)
/// 最大能量值
const speedupTimesMax = ref(0)

/// 每秒默认回复值
let def_energy = 1

/// 是否回到后台
const appbackstageRef = ref(false);

/// 倒计时
const timerId = ref(null);
/// 回复能量定时器
const energy_timerId = ref(null);

const backgroundColors = ref([
  "#000000",
  "#BA2B00",
  "#C96C00",
  "#D6B91B",
  "#4A9808",
  "#04AAAA",
  "#0253A3",
  "#2D0B89"
]);

/// 获取用户信息
const getUser = () => {
  /// 上线时打包时注意打开
  var userTgName = "";

  let tgUser = window.Telegram.WebApp.initDataUnsafe.user;
  if (tgUser) {
    const { id, username, first_name, last_name } = tgUser;
    userTgId.value = id;
    console.log("用户id" + id);
    console.log("用户名" + username + first_name + last_name);
    userTgName = username ? username : first_name + " " + last_name;
  } else {
    /// 如果未获取到用户信息 则3s后再重新获取
    console.log('未获取到用户信息 ')
    setTimeout(() => {
      getUser();
    }, 3000);
    return;
  }

  // userTgId.value = 7278441621
  // userTgName = 'left_jerry'

  const aesTgId = encryptedString(userTgId.value);
  const params = {
    originPlatform: 201,
    tgId: aesTgId,
    tgAccount: userTgName,
  };
  getUserInfo(params).then((response) => {
    uptateUser(response);
  });
};

/// 获得奖励
const getTgRewardRecord = (amount) => {
  const aesTgId = encryptedString(userTgId.value);
  const aesAmount = encryptedString(amount);
  const params = {
    tgId: aesTgId,
    amount: aesAmount,
    originPlatform: 201,
    type: 3001,
    speedupTimesRemaining:speedupTimesRemaining.value
  };

  tgRewardRecord(params).then((response) => {
    console.log("获取奖励成功" + response);
    walletnum.value += amount;
    /// 刷新用户信息
    getUser();
  });
};

/// 加入社区获得奖励
const joinRewordAdd = (type) => {
  walletnum.value += 100;
  if (type == 2011) {
    communityRef.value = true;
  } else if (type == 2021) {
    followAnnRef.value = true;
  } else {
    followXRef.value = true;
  }
};

/// 获取是否是通过邀请进入的  如果是通过邀请链接进入 则这里调用邀请用户信息的 用户详情
const getStart_parma = () => {
  const start_param = window.Telegram.WebApp.initDataUnsafe.start_param;
  if (start_param) {
    var base1 = decode(start_param);
    var ss = base1.split("##");
    let invitedTgId = ss[0];
    let invitedTgAccount = ss[1];
    console.log("有邀请信息" + invitedTgId, invitedTgAccount, base1);

    var userTgName = "";
    let tgUser = window.Telegram.WebApp.initDataUnsafe.user;
    if (tgUser) {
      const { id, username, first_name, last_name } = tgUser;
      userTgId.value = id;
      userTgName = username ? username : first_name + " " + last_name;
    } else {
      /// 如果未获取到用户信息 则3s后再重新获取
      setTimeout(() => {
        getStart_parma();
      }, 3000);
      return;
    }

    const aesTgId = encryptedString(userTgId.value);
    const params = {
      originPlatform: 201,
      tgId: aesTgId,
      tgAccount: userTgName,
      invitedTgId: invitedTgId,
      invitedTgAccount: invitedTgAccount,
    };
    getUserInfo(params).then((response) => {
      uptateUser(response);
    });
  } else {
    getUser();
  }
};

/// 随机背景颜色
const randomBgColor = () => {
  var element;
  do {
    const index = Math.floor(Math.random() * backgroundColors.value.length);
    element = backgroundColors.value[index];
  } while (element === randomColor.value)  ///确保每次跟上一个颜色不同
  return element
};

const uptateUser = (response) => {
  console.log("用户余额:" + response.balance);
  let balance = response.balance ? response.balance : 0;
  walletnum.value = balance;
  communityRef.value = response.joinCommunity;
  followAnnRef.value = response.followAnn;
  followXRef.value = response.followX;
  speedupTimesRemaining.value = response.speedupTimesRemaining
  speedupTimesMax.value = response.speedupTimesMax

};

/// 弹出加入页或 欢迎页 回到后台 时停止倒计时
const stopCountEnble = () => {
  if (
    joinisVisible.value == true ||
    welcomeRef.value.welcomeisVisible ||
    balanceisVisible.value ||
    !countDownEnble.value ||
    appbackstageRef.value
  ) {
    return true;
  } else {
    return false;
  }
};

/// 开启回复能量定时器
const startEnergy_Countdown = () => {
  if (!energy_timerId.value) {
    energy_timerId.value = setInterval(() => {      
      if (speedupTimesRemaining.value >= speedupTimesMax.value ) {
        return
      }
      speedupTimesRemaining.value += def_energy
    }, 1000);
  }
};

const startCountdown = () => {
  if(!timerId.value){
      timerId.value = setInterval(() => {
      /// 弹出分享页或欢迎页时 禁止倒计时
      if (stopCountEnble()) {
        return;
      }

      if (count.value > 1) {
        playSound();
        count.value--;
        randomColor.value = randomBgColor();
        //console.log('倒计时' + count.value)
      } else {
        /// 倒计时结束 暂停倒计时
        pauseCountdown();
        countdownEnd();
        startEnergy_Countdown()
      }
    }, 1000);
    } 
};

const handleClick = () => {
  // 弹窗时禁止点击事件
  if (stopCountEnble() || speedupTimesRemaining.value <= 0) {
    console.log("禁止点击了呀" + welcomeRef.value.welcomeisVisible);
    return;
  }

  // if (timerId.value) {
  //   pauseCountdown();
  // }

  pauseCountdown();
  pauseEnergyCountdown()

  // can_energy.value = false

  // 点击时 背景随机颜色
  randomColor.value = randomBgColor();

  if (count.value > 1) {
    afterStart();
    playSound();
    count.value -= 1;
    speedupTimesRemaining.value -=1;
  } else {
    countdownEnd();
  }
};

/// 点击屏幕暂停倒计时 1s后再次启动倒计时
const afterStart = () => {
  setTimeout(() => {
    startCountdown();
    startEnergy_Countdown();
  }, 1000);
};

// 点击进入邀请页面
function joinClick(event) {
  event.stopPropagation(); // 阻止div倒计数的点击事件 响应join事件
  joinisVisible.value = true;

  if (cointisVisible.value) {
    coinRef.value.joinPauseCount();
  }

  /// 获取用户邀请列表
  joinRef.value.getInviteList();
  joinRef.value.verifyAll(
    communityRef.value,
    followAnnRef.value,
    followXRef.value
  );

  /// 返回键显示back
  window.Telegram.WebApp.BackButton.show();
}

const welcomeVisble = () => {
  resetCountdown();
};

const restHomeData = () => {
  cointisVisible.value = false;
  countisVisible.value = true;
  welcomeRef.value.afterStart(another);
};

/// 停止定时器
const pauseCountdown = () => {
  // isCountingDown.value = false;
  if (timerId.value) {
    clearInterval(timerId.value);
    timerId.value = null;
  }
};

/// 停止并释放定时器
const pauseEnergyCountdown = () => {
  if (energy_timerId.value) {
    clearInterval(energy_timerId.value);
    energy_timerId.value = null;
  }
};

/// 重置倒计时
const resetCountdown = () => {
  count.value = 30;
  setTimeout(() => {
    countDownEnble.value = true;
    startCountdown();
    startEnergy_Countdown();
  }, 1000);
};

/// 倒计时结束
const countdownEnd = () => {
  countisVisible.value = false;
  cointisVisible.value = true;
  countDownEnble.value = false;
  console.log("首页的倒计时结束");
  coinRef.value.afterStart(true);
  /// 倒计时结束时上传一次剩余点击次数
  updateSpeedupTimes()
};

/// 更新最新点击次数
const updateSpeedupTimes = () => {
  const aesTgId = encryptedString(userTgId.value);
  const params = {
    originPlatform: 201,
    tgId: aesTgId,
    speedupTimesRemaining: speedupTimesRemaining.value,
  };
  putUserInfo(params).then((response) => {
    console.log(response)
  });
}

/// 点击跳转余额页面
const balanceClick = () => {
  balanceisVisible.value = true
  window.Telegram.WebApp.BackButton.show();
}

/// 钱包数字滚动事件
const getCoinAnimation = (amount) => {
  getTgRewardRecord(amount);
};

// 开启倒计时声音
const playSound = () => {
  sound.value.play();
};

const clearSound = () => {
  // sound.src = ''
  sound.value.pause();
};

const handleVisibilityChange = () => {
  if (document.visibilityState === "hidden") {
    console.log("用户回到了后台");
    appbackstageRef.value = true;
    // 执行回到后台的逻辑
    if (cointisVisible.value) {
      coinRef.value.hideApp();
    }
  } else if (document.visibilityState === "visible") {
    console.log("用户返回前台");
    appbackstageRef.value = false;
    // 执行返回前台的逻辑
    if (cointisVisible.value) {
      coinRef.value.visibleApp();
    }

    /// 安卓手机后台返回前台会 导致back按钮消失 所以这里再重新backshow
    if (joinRef.value.downloadVisible) {
      window.Telegram.WebApp.BackButton.show();
    }
  }
};

/// 加载组件
onMounted(() => {
  console.log('开始创建了')

  getStart_parma();
  document.addEventListener("visibilitychange", handleVisibilityChange);

  sound.value = new Howl({
    src: require("@/assets/tg_countdown.mp3"),
    volume: 0.5,
    html5: false,
    onend: function () {
      //console.log('声音播放Finished!');
    },
  });

  try {
    /// 默认全屏
    window.Telegram.WebApp.expand();
    /// app关闭弹窗
    window.Telegram.WebApp.enableClosingConfirmation();
  } catch (e) {
    console.log(e);
  }

  // 监听关闭app事件
  window.Telegram.WebApp.onEvent("popupClosed", (e) => {
    clearSound();
    console.log("关闭了app" + e);
  });

  /// 点击返回键
  window.Telegram.WebApp.BackButton.onClick(() => {
    /// 优先隐藏分享弹窗
    if (joinRef.value.inviteVisible) {
      joinRef.value.inviteVisible = false;
      return;
    }

    // 隐藏下载的弹窗
    if (joinRef.value.downloadVisible) {
      joinRef.value.downloadVisible = false;
      return;
    }

    if(balanceisVisible.value){
      balanceisVisible.value = false
    }

    // 如果是领币页已显示 则继续启动领币页倒计时
    if (cointisVisible.value) {
      coinRef.value.joinStartCount();
    }
    joinisVisible.value = false;
    window.Telegram.WebApp.BackButton.hide();
  });
});

onUnmounted(() => {

  console.log('销毁了首页啊')
  // 组件卸载时清理定时器
  pauseCountdown();
  pauseEnergyCountdown();
  clearSound();
  document.removeEventListener("visibilitychange", handleVisibilityChange);
});
</script>

<style scoped>
.full-size {
  display: flex;
  width: 100vw; /* 视口宽度 100%宽度 */
  height: 100vh; /*视口高度* 100%高度 */
  /* 防止内容溢出  这个会影响滑动*/
  /* overflow: hidden;  */ 
  /* 水平垂直居中 */
  justify-content: center;
  align-items: center;
  /* 可选的背景颜色过渡效果 */
  transition: background-color 0.2s;
}


.container-mid {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; 使用视窗高度来使容器占满整个屏幕 */
}

.wallet_container {
  display: flex;
  justify-content: center;
  color: white;
}

.wallet_num {
  font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
  font-size: 30px;
  color: white;
}

.count-font {
  font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
  font-size: 300px;
  color: white;
  text-align: center;
}

.join-font {
  font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
  font-size: 20px;
}

.gift-container {
  display: flex;
  position: absolute;
  bottom: 25px;
}

.energy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.energy-container img {
  margin-bottom: 10px;
  width: 45px;
  height: 45px;
}

.spacer {
  margin: 0 20px; /* 水平偏移10px */
}

.underline {
  position: absolute;
  bottom: 0; /* 位于容器底部 */
  width: 100%; /* 宽度与容器相同 */
  height: 2px; /* 下划线的高度 */
  background-color:white; /* 下划线颜色 */
  transition: bottom 0.3s; /* 可以为下划线的位置变化添加动画 */
}

</style>