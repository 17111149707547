<!-- 自定义弹窗 -->
<template>
  <div v-show="isVisible" class="join-size">

    <div class="container">
      <div class="wallet_container" style="margin-top: 30px">
      <IOdometer class="wallet_num" :value="walletBalance" ></IOdometer>
      <div class="wallet_num" style="margin-left: 10px;">{{ boredYet }}</div>
    </div>

    <div >
      <div class="join-container bordered-div" @click.stop="clickCommunity" style="margin-top: 30px;">
        <div class="join-font marginleft">Join Community +100 $BORED</div>
        <div>
          <div class="join-font under-line go" v-show="communityLoading" >{{ communityGo }}</div>
          <img class="loading-size" v-show="!communityLoading" :class="{'loading-rotate-animation':communityLoadingRotate}" :src='communityImg' alt="">
        </div>
        
      </div>

    <div class="join-container bordered-div" @click.stop="clickFllowAnn">
        <div class="join-font marginleft">Follow ANN +100 $BORED</div>
        <div>
          <div class="join-font under-line go" v-show="followAnnLoading" >{{ followAnnGo }}</div>
          <img class="loading-size" v-show="!followAnnLoading" :class="{'loading-rotate-animation':followAnnLoadingRotate}" :src='followAnnImg' alt="">
        </div>

    </div>

    <div class="join-container bordered-div" @click.stop="clickFollwX">
        <div class="join-font marginleft">Follow X +100 $BORED</div>
        
        <div>
          <div class="join-font under-line go" v-show="followXLoading" >{{ followXGo }}</div>
          <img class="loading-size" v-show="!followXLoading" :class="{'loading-rotate-animation':followXLoadingRotate}" :src='followXImg' alt="">
        </div>
    </div>

    <div class="join-container bordered-div" @click.stop="clickDownload">
        <div class="win-contain">
          <div class="join-font marginleft join-msg" >Win 10$YET</div>
          <div class="join-font " style="font-size: 8px;">(BEP20)</div>
          <div class="join-font join-msg" style="white-space: pre;">{{ win30sApp }}</div>
        </div>
        <div class="join-font under-line go" >GO</div>
    </div>

    <div class="invite-friends-container bordered-div" style="margin-bottom: 30px;">
      <div class="invite-friends ">
        <div class="join-font marginleft">Invite Friends +100 $BORED</div>
        <div class="join-font under-line go" @click.stop="clickInviteFriends">GO</div>
      </div>
        
      <div class="nofriends-yet" v-show="noinviteVisible">
        <div class="nofriends-yet-msg" >No Friends Yet</div>
      </div>

      <div style="min-height: 250px;" v-show="!noinviteVisible">
        <div class="invite-container"  v-for="(item,index) in list " :key="index">
        <div class="user-container">
            <div class="header" :style="{backgroundColor:randomColor()}">{{ firstLetterCapitalized(item.tgAccount) }}</div>
            <div class="invite-font-size user-name">{{ item.tgAccount }}</div>
        </div>
        <div class="invite-font-size">+100 $BORED</div>
      </div>
      </div>

    </div>
    </div>

    <div class="show-share-container" v-show="inviteVisible">
      <div class="under-line" @click.stop="copyInviteLink" style="margin-bottom: 40px" :style="{color:shareCopyColor}">{{ copyLine }}</div>
        <div class="under-line" @click.stop="shareInviteLink">SHARE INVITE LINK</div>
    </div>

    </div>

    <TGDownLoad class="download-container" v-show="downloadVisible"></TGDownLoad>
  </div>
  
</template>
   
<script setup>
import "@/assets/fonts/font.css";
import IOdometer from 'vue3-odometer';
import 'odometer/themes/odometer-theme-default.css';
import { encode } from 'js-base64';

import tgLoadingImg from '@/assets/images/tg_loading.png';
import tgGouImg from '@/assets/images/tg_gou.png';

import TGDownLoad from "./downloadComponent.vue";
import {ref,defineExpose,defineProps,onMounted,defineEmits } from "vue";
import { getUserInfo,tgInviteList ,tgRewardRecord} from '@/utils/api';
import { encryptedString } from '@/utils/tools';

/// 是否已copy
const shareIsCopy = ref(false)
const shareCopyColor = ref('#FFFFFF')
const copyLine = ref('COPY INVITE LINK')
const boredYet = '$BORED'
const win30sApp = ' 30sec APP'

const communityGo = ref('GO')
const followAnnGo = ref('GO')
const followXGo = ref('GO')

const noinviteVisible = ref(false)

const communityImg = ref(tgLoadingImg)
const followAnnImg = ref(tgLoadingImg)
const followXImg = ref(tgLoadingImg)

const communityLoading = ref(true)
const communityLoadingRotate = ref(false)

const followAnnLoading = ref(true)
const followAnnLoadingRotate = ref(false)

const followXLoading = ref(true)
const followXLoadingRotate = ref(false)
const downloadVisible = ref(false)

const list = ref([])

const headerColors = ref(['#444444', '#666666', '#888888', '#999999']);

/// 验证类型
const verifyEnum = {
      communty: 'communty',
      followAnn: 'followAnn',
      followX: 'followX',
  };

/// 邀请页面是否显示
const inviteVisible = ref(false)
const emit = defineEmits(["joinReword"]);

const props = defineProps({
  isVisible:Boolean,
  walletBalance: {
    default: 0
  }
})

/// 随机背景颜色
const randomColor = () => {
  const index = Math.floor(Math.random() * headerColors.value.length);
  return headerColors.value[index];
}

const firstLetterCapitalized = (name) => {
  if (name && name.length > 0) {
    return name[0].toUpperCase()
  }
  return '';
}

/// 将奖励传回父类
const getJoinReword = (type) => {
  emit('joinReword',type)
}

/// 加入30s社区
const clickCommunity = () => {

  if (communityGo.value == '√') {
    return
  }

  if (communityGo.value == 'CLAIM') {
    communityLoading.value = false
    communityLoadingRotate.value = true
    getTgJoinRewardRecord(2011)
    setTimeout(() => {
      joinVerify(verifyEnum.communty);
  }, 10000); 
    return
  }

  ///上线时注意打开
  /// 测试群 注意修改
  const communityUrl = 'https://t.me/test3030s'
  /// 正式群
  // const communityUrl = 'https://t.me/yet30sec'
  window.Telegram.WebApp.openTelegramLink(communityUrl)

  communityGo.value = 'CLAIM'
}

/// 加入30sec公告群
const clickFllowAnn = () => {

  if (followAnnGo.value == '√') {
    return
  }

  if (followAnnGo.value == 'CLAIM') {
    followAnnLoading.value = false
    followAnnLoadingRotate.value = true
    getTgJoinRewardRecord(2021)
    setTimeout(() => {
      joinVerify(verifyEnum.followAnn);
  }, 10000); 
    return
  }

  ///上线时注意打开
  /// 测试Ann群
  const url = 'https://t.me/+ilF9KFq9vQU2ZTdl'

  /// 正式Ann群
  // const url = 'https://t.me/info30sec'
  window.Telegram.WebApp.openTelegramLink(url)

  followAnnGo.value = 'CLAIM'
}


/// 关注推特
const clickFollwX = () => {

  if (followXGo.value == '√') {
    return
  }

  if (followXGo.value == 'CLAIM') {
    followXLoading.value = false
    followXLoadingRotate.value = true
    getTgJoinRewardRecord(2022)
    setTimeout(() => {
      joinVerify(verifyEnum.followX);
  }, 10000); 
    return
  }

  const url = 'https://x.com/30sec_yet'
  window.Telegram.WebApp.openLink(url)

  followXGo.value = 'CLAIM'
}

/// 请求加入社区等验证
const joinVerify = (type) => {
  const { id, username,first_name,last_name } = window.Telegram.WebApp.initDataUnsafe.user;
  let userTgId = id
  let userTgName = username ? username : (first_name + ' ' + last_name)

  // let userTgId = 7278441621
  // let userTgName = 'left_jerry'
  
  const aesTgId = encryptedString(userTgId)
  const params = {
    originPlatform:201,
    tgId:aesTgId,
    tgAccount:userTgName
  }
  getUserInfo(params).then(response => {
    if (type == verifyEnum.communty) {
      let commuuntyVerifySucced = response.joinCommunity ? response.joinCommunity : false
      communityVerfy(commuuntyVerifySucced)
      if (commuuntyVerifySucced){
        getJoinReword(2011)
      } 
    }

    if (type == verifyEnum.followAnn) {
      let followAnnVerifySucced = response.followAnn ? response.followAnn : false
      followAnnVerfy(followAnnVerifySucced)
      if (followAnnVerifySucced){
        getJoinReword(2021)
      } 
    }

    if (type == verifyEnum.followX) {
      let followXVerifySucced = response.followX ? response.followX : false
      followXVerfy(followXVerifySucced)
      if (followXVerifySucced){
        getJoinReword(2022)
      } 
    }
  });
}

/// 验证加入社区
const communityVerfy = (result) => {
  communityLoadingRotate.value = false
  communityLoading.value = !result
  communityImg.value = result ? tgGouImg : tgLoadingImg
  communityGo.value = result ? '√' : 'GO'
}

const followAnnVerfy = (result) => {
  followAnnLoadingRotate.value = false
  followAnnLoading.value = !result
  followAnnImg.value = result ? tgGouImg : tgLoadingImg
  followAnnGo.value = result ? '√' : 'GO'
}

const followXVerfy = (result) => {
  followXLoadingRotate.value = false
  followXLoading.value = !result
  followXImg.value = result ? tgGouImg : tgLoadingImg
  followXGo.value = result ? '√' : 'GO'
}

/// 获取邀请列表
const getInviteList = () => {

  /// 上线时打包时注意打开
  const { id } = window.Telegram.WebApp.initDataUnsafe.user;
  let userTgId = id

  // let userTgId = 7278441621

  const aesTgId = encryptedString(userTgId)
  const params = {
    originPlatform:201,
    tgId:aesTgId,
  }
  tgInviteList(params).then(response => {
    list.value = response ? response : []
    noinviteVisible.value = (list.value.length == 0 )
  });
}

/// 验证全部
const verifyAll = (tgcommunity,tgfollowAnn,tgfollowX) => {
  communityVerfy(tgcommunity)
  followAnnVerfy(tgfollowAnn)
  followXVerfy(tgfollowX)
}

/* 获得加入社区等奖励
2001 30秒TV获取
2011 Join Community
2021 Follow ANN
2022 Follow X
3001 无聊30秒获取
*/
const getTgJoinRewardRecord = (type) => {
  let userTgId = window.Telegram.WebApp.initDataUnsafe.user.id;
  const aesTgId = encryptedString(userTgId)
  const aesAmount = encryptedString(100)
  const params = {
    tgId:aesTgId,
    amount:aesAmount,
    originPlatform:201,
    type:type
  }

  tgRewardRecord(params).then(response => {
    console.log('关注推特获取奖励成功' + response)
  });
}

/// 点击进入30s app
const clickDownload = () => {
  downloadVisible.value = true
}

/// 点击分享邀请好友
const clickInviteFriends = () => {
  inviteVisible.value = true
}

/// 点击拷贝分享link
const copyInviteLink = () => {

  if(shareIsCopy.value){
    return
  }

  const { id, username,first_name,last_name } = window.Telegram.WebApp.initDataUnsafe.user;
  var userTgName = username ? username : (first_name + ' ' + last_name)
  var b = id+"##"+userTgName
  var s = "startapp=" + encode(b)
  var u = "https://t.me/share/url?url=https://t.me/BORED30sec_bot/webapp?" + s + '&text=' + 'Get $BORED in 30sec with me!'

  navigator.clipboard.writeText(u).then(
    () => {
      console.log('复制成功')
    },
    () => {
      console.log('复制失败')
    }
  );

  shareIsCopy.value = true
  copyLine.value = 'Copied'
  shareCopyColor.value = '#888888'
  setTimeout(() => {
    shareIsCopy.value = false
    copyLine.value = 'COPY INVITE LINK'
    shareCopyColor.value = '#FFFFFF'
  }, 2000); 
}

//shareInviteLink
const shareInviteLink = () => {
  const { id, username,first_name,last_name } = window.Telegram.WebApp.initDataUnsafe.user;
  var userTgName = username ? username : (first_name + ' ' + last_name)
  var b = id+"##"+userTgName
  var s = "startapp=" + encode(b)
  var u = "https://t.me/share/url?url=https://t.me/BORED30sec_bot/webapp?" + s + '&text=' + 'Get $BORED in 30sec with me!'   
  console.log(u)
  window.Telegram.WebApp.openTelegramLink(u)
}

onMounted(() => {
  getInviteList()
});

/// 暴露子组件方法 这里需要暴露出去 不然父类组件调用不到
defineExpose({
  getInviteList,
  verifyAll,
  downloadVisible,
  inviteVisible,
  props
});

</script>
   
<style scoped>
.join-size {
  position: fixed;
  background-color: black;
}

.download-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.container {
  display: flex;
  /* 垂直布局 */
  flex-direction: column; 
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.join-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    margin: 15px 20px 15px 20px;
}

.win-contain{
  display: flex;
  align-items: center;
  flex-direction: row; 
}

.invite-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin: 1px 15px 15px 15px;
}

.invite-friends-container {
  margin: 15px 20px 5px 20px;
}

.invite-friends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
}

.wallet_container {
  display: flex;
  justify-content: center;
}

.wallet_num {
  font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
  font-size: 30px;
  color: white;
}

.bordered-div {
  border: 1.5px solid white;
}

.join-list{
  font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
  font-size: 18px;
  color: white;
  margin-bottom: 30px;
}

.go {
  margin-right: 15px;
  font-size: 17px;
}

.under-line {
  text-decoration: underline;
}

.nofriends-yet {
  font-family: "PublicSans-Medium", sans-serif; /* 设置字体*/
  display: flex;
  height: 250px;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  color: #888888;
}

.nofriends-yet-msg{
  position: relative; /* 相对定位 */
  top: -23px; /* 垂直偏移 */
}

.join-font{
  font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
  color: white;
}

.marginleft{
  margin-left: 14px;
}

.show-share-container {
  background-color: black;
  position: absolute;
  width: 100vw;
  height: 100vh;
  /* 透明度 */
  opacity: 0.9; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 

  font-family: "JohnHancockCP", sans-serif; /* 设置字体*/
  font-size: 20px;
  color: white;
}

.loading-size {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  object-fit: contain; 
}

.header{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: "PublicSans-Medium", sans-serif; /* 设置字体*/
  color: white;
  width: 40px;
  height: 40px;
}

.user-container {
    display: flex;
    align-items: center;
}

.user-name {
  margin-left: 10px;
}

.invite-font-size{
    color: white;
    font-size: 14px;
    font-family: "PublicSans-Medium", sans-serif;
}

.loading-rotate-animation{
    animation: loading-rotate  1s linear infinite;
}

@keyframes loading-rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

</style>